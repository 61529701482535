<template>
  <CCardHeader>
    <CRow class="align-items-center">
      <CCol sm="12" md="6">
        <h4 class="mb-0 card-title">Cookies</h4>
      </CCol>
      <CCol sm="12" md="6" class="card-header-actions">
        <CButton
          v-if="showRemoveAll"
          color="danger"
          class="float-right ml-2"
          @click="$emit('remove', null)"
        >
          <CIcon name="cil-trash" class="mr-1" />
          <span>Remover cookies</span>
        </CButton>
        <CButton color="primary" class="float-right" @click="$emit('add')">
          <CIcon name="cil-library-add" class="mr-2" />
          Adicionar cookie
        </CButton>
      </CCol>
    </CRow>
  </CCardHeader>
</template>

<script>
export default {
  props: { showRemoveAll: { type: Boolean, required: true } }
}
</script>
