<template>
  <CCard class="card-static-seo">
    <CCardHeader>
      <CRow class="align-items-center">
        <CCol>
          <b class="mb-0"> Detalhes do cookie </b>
        </CCol>
        <CCol>
          <div class="card-header-actions">
            <a
              v-c-tooltip="'Remover'"
              href="javascript:void(0)"
              class="d-flex align-items-center text-danger"
              placement="top"
              @click="$emit('handleRemove')"
            >
              <CIcon name="cil-trash"> </CIcon>
            </a>
          </div>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol lg="12">
          <div class="form-group">
            <label :for="`category-${index}`">Categoria</label>
            <Multiselect
              :id="`category-${index}`"
              :value="selectedCategory"
              :options="categories"
              :required="true"
              @select="({ value }) => (item.category = value)"
            />
          </div>
        </CCol>
        <CCol sm="12">
          <CInput
            v-model="item.title"
            label="Título"
            placeholder="Digite o título do cookie"
            :description="titleDesc"
            :maxlength="titleLength"
            :required="true"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            v-model="item.slug"
            label="Identificador"
            placeholder="Ex: _ga_"
            :add-input-classes="`input-url-${index}`"
            :required="true"
          />
        </CCol>
        <CCol sm="12">
          <CTextarea
            v-model="item.description"
            label="Descrição"
            placeholder="Digite a descrição da cookie"
            class="mb-0"
            :description="descriptionDesc"
            :maxlength="descriptionLength"
          />
        </CCol>
        <CCol col="12">
          <div class="mt-1 ml-3 d-flex align-items-center">
            <label class="mb-0">
              <b> Usúario poderá ativar ou inativar este cookie? </b>
            </label>
            <CSwitch
              type="checkbox"
              color="success"
              variant="3d"
              class="mx-2"
              v-bind="labelIcon"
              :checked.sync="item.optional"
            />
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Multiselect from '@/components/ui/Multiselect.vue'

export default {
  props: { value: { type: Object, required: true } },
  components: { Multiselect },

  data: () => ({
    titleLength: 70,
    descriptionLength: 160,
    labelIcon: { labelOn: '\u2713', labelOff: '\u2715' },
    categories: [
      { value: 1, label: 'Necessário' },
      { value: 2, label: 'Marketing' },
      { value: 3, label: 'Analytics' },
      { value: 4, label: 'Outros' }
    ]
  }),

  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:value', val)
      }
    },
    descriptionDesc() {
      const descriptionLength = this.item.description.length
      const maxLength = this.descriptionLength
      return `${descriptionLength}/${maxLength} caracteres`
    },
    titleDesc() {
      const titleLength = this.item.title.length
      const maxLength = this.titleLength
      return `${titleLength}/${maxLength} caracteres`
    },
    selectedCategory() {
      return this.categories.find(({ value }) => value === this.item.category)
    }
  }
}
</script>
