<template>
  <div class="static-cookies">
    <RemoveConfirmation
      v-model="modal"
      @confirm="removeItem"
      @cancel="resetRemoveConfirmation"
    />

    <CForm @submit.prevent="send">
      <CRow>
        <CCol col="12">
          <CCard class="border-0">
            <Headers
              @remove="handleRemoveItem"
              @add="addItem"
              :show-remove-all="!!items.length"
            />

            <CCardBody>
              <CAlert color="info" v-if="!completeCookies">
                ⚠️&nbsp;Para exibir essas informações é necessário ativar o modo
                avançado dos cookies nas
                <a href="/settings" target="_self">configurações</a>.
              </CAlert>

              <!-- Content -->
              <CRow v-if="items.length > 0">
                <CCol
                  v-for="(item, index) in items"
                  :key="index"
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <CookieItem
                    v-model="items[index]"
                    @handleRemove="handleRemoveItem(index)"
                  />
                </CCol>
              </CRow>

              <!-- No content -->
              <p v-else class="mb-0 text-muted">
                Não há cookies cadastrados,
                <a href="javascript:void(0)" class="text-info" @click="addItem">
                  clique aqui
                </a>
                para adicionar um novo cookie.
              </p>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol class="mt-3 mb-5">
          <div class="d-flex justify-content-end align-items-center">
            <CButton color="success" type="submit" :disabled="processing">
              {{ processing ? 'Salvando...' : 'Salvar' }}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CForm>
  </div>
</template>

<script>
import Headers from './Headers.vue'
import RemoveConfirmation from './RemoveConfirmation.vue'
import CookieItem from './CookieItem.vue'

import CookiesService from '@/services/cookies.service'

const getDefaultModal = () => ({
  show: false,
  title: '',
  message: ''
})

const getDefaultCookieItem = () => ({
  category: '',
  slug: '',
  title: '',
  description: '',
  optional: true
})

export default {
  metaInfo: {
    title: 'Cookies',
    titleTemplate: ' %s - Manager - Rodonaves Iveco'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_cookies')) {
        vm.$router.push('/404')
      }
    })
  },

  components: { RemoveConfirmation, Headers, CookieItem },

  data: () => ({
    items: [],
    pageToRemove: null,
    processing: false,
    modal: getDefaultModal()
  }),

  created() {
    this.getItems()
  },

  methods: {
    addItem() {
      this.items = [getDefaultCookieItem(), ...this.items]
    },

    handleRemoveItem(index) {
      const messageFragment =
        index !== null
          ? 'o cookie não poderá ser recuperado'
          : 'os cookies não poderão ser recuperados'

      const title = `Remover ${index !== null ? 'cookie' : 'todos os cookies'}`
      const message = `Ao confirmar essa ação ${messageFragment}, tem certeza que desejar continuar?`

      this.modal = { show: true, title, message }
      this.pageToRemove = index
    },

    removeItem() {
      const indexToRemove = this.pageToRemove

      this.items = this.items.filter((_, index) =>
        indexToRemove === null ? false : index !== indexToRemove
      )

      this.resetRemoveConfirmation()
    },

    resetRemoveConfirmation() {
      this.pageToRemove = null
      this.modal = getDefaultModal()
    },

    async getItems() {
      const data = await CookiesService.listAll()

      this.items = data.map((item) => ({
        category: item.category,
        slug: item.slug,
        title: item.title,
        description: item.description,
        optional: item.optional
      }))
    },

    async send() {
      this.processing = true
      const notification = {
        group: 'notifications',
        type: 'success',
        text: 'Cookies salvos com sucesso!'
      }

      try {
        await CookiesService.setCookies(this.items)
      } catch {
        notification.type = 'error'
        notification.text =
          'Erro ao salvar cookies de paginas, tente novamente mais tarde.'
      } finally {
        this.$notify(notification)
        this.processing = false
      }
    }
  },
  computed: {
    completeCookies() {
      const { settings } = this.$store.state.settings
      return settings.cookies_notification_version === 'complete'
    }
  }
}
</script>

<style lang="scss">
.card-static-cookies {
  textarea {
    height: 80px !important;
  }
}

@media only screen and (max-width: 768px) {
  .static-cookies {
    .card {
      .card-title {
        margin-bottom: 1em !important;
      }

      .card-header-actions {
        button {
          float: none !important;
          margin-left: 0px !important;
          margin-right: 0.5rem !important;

          &:last-child {
            margin-right: 0px !important;
          }
        }
      }
    }
  }
}
</style>
