import axios from './axios.service'

const method = 'cookies'

class CookiesService {
  async listAll() {
    try {
      const response = await axios.get(`/${method}`)

      return response.data
    } catch (error) {
      return error
    }
  }

  async setCookies(items) {
    await axios.post(`/${method}`, { items })
  }
}

export default new CookiesService()
